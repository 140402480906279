
import Particles from 'react-particles';
import { loadSlim } from "tsparticles-slim";
import './css/App.css';
import './css/responsive.css';
import { useCallback, useState } from 'react';
import { particlesOptions, particlesOptions1 } from './utils/particlesOptions.ts';
import { t } from './utils/translate.ts';

function App() {
  const [lang, setLang] = useState(localStorage.getItem('lang') ?? 'en');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');

  const particlesInit = useCallback(async engine => {
      await loadSlim(engine);
  }, []);

  const changeLanguage = () => {
    if (lang === 'en') {
      localStorage.setItem('lang', 'ar')
      setLang('ar');
    }
    if (lang === 'ar') {
      setLang(localStorage.setItem('lang', 'en'));
      setLang('en');
    }
  }
  
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const sendEmail = () => {
    if (!name.length) return window.alert(t('invalidName'))
    if (!validateEmail(email)) return window.alert(t('invalidEmail'))
    if (!msg.length) return window.alert(t('invalidMsg'))

    var formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('msg', msg);
    fetch('https://ukeee.co.uk/php/mail.php', {
      method: 'post',
      body: formData
    })
    .then((res) => res.text())
    .then((resText) => {
      window.alert(t('mailSent'))

      setName('');
      setEmail('');
      setMsg('');
    })
    .catch((e) => {
      setName('');
      setEmail('');
      setMsg('');
    });
  }

  return (
    <div style={lang === 'ar'? { direction: 'rtl', fontFamily: 'Rubik' }: undefined}>
      {lang === 'ar'?
      <link type="text/css" href='./css/rtl.css' />
      : null}

      <div className='header'>
        <a href='#'><img src={require('./assets/logo.png')} /></a>

        <div className='navs'>
          <a href='#home'>{t('Home')}</a>
          <a href='#services'>{t('Services')}</a>
          <a href='#contact'>{t('Contact Us')}</a>
        </div>

        <div className='navs'>
          <a onClick={changeLanguage}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-globe"><circle cx="12" cy="12" r="10"/><line x1="2" x2="22" y1="12" y2="12"/><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"/></svg>
            {lang === 'ar'? 'EN': 'AR'}
          </a>
        </div>
      </div>

      <Particles
        id="tsparticles"
        init={particlesInit}
        className='particles'
        options={particlesOptions}
      />
      <div className='about' id='home'>
        <div className='about-container'>
          <div>
            <div className='logoContainer'><img src={require('./assets/logo.png')} /></div>
          </div>
          <div className='about-content'>
            <div className='title'>
              <p>{t('Who we are?')}</p>
              <div></div>
            </div>
            <p>{t('AboutText')}</p>
          </div>
        </div>

        <div className='button-container'>
          <a href='#services' className='button'>{t('Services')}</a>
        </div>
      </div>

      <div className='vision'>
        <div className='title'>
          <p>{t('Vision and Mission')}</p>
          <div></div>
        </div>
        <p>{t('VisionText')}</p>
      </div>

      <div className='services' id='services'>
        <div className='title'>
          <p>{t('Services')}</p>
          <div></div>
        </div>

        <div className='service-container'>

          <div className='service-title'>
            <div>{t('Education')}</div>
            <img src={require('./assets/image.png')} />
          </div>

          <div className='service-details'>
            <div className='title'>
              <p>{t('Quality of Education')}</p>
              <div></div>
            </div>
            <p>{t('QualityText')}</p>
          </div>

        </div>

        <div className='service-container'>

          <div className='service-details'>
            <div className='title'>
              <p>{t('Training')}</p>
              <div></div>
            </div>
            <p>{t('TrainingText')}</p>
          </div>

          <div className='service-details'>
            <div className='title'>
              <p>{t('Training areas we cover')}</p>
              <div></div>
            </div>
            <div className='service-areas'>
              <div>
                <img src={require('./assets/areas/1.png')} />
                <div>{t('Business & Leadership')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/2.png')} />
                <div>{t('IT')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/3.png')} />
                <div>{t('Construction')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/4.png')} />
                <div>{t('Education')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/5.png')} />
                <div>{t('Energy')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/6.png')} />
                <div>{t('Finance')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/7.png')} />
                <div>{t('Engineering')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/8.png')} />
                <div>{t('Human Resources')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/9.png')} />
                <div>{t('Hospitality')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/10.png')} />
                <div>{t('Healthcare & Medical')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/11.png')} />
                <div>{t('Informational')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/12.png')} />
                <div>{t('Military')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/13.png')} />
                <div>{t('Politics')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/14.png')} />
                <div>{t('Public')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/15.png')} />
                <div>{t('Media')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/16.png')} />
                <div>{t('Retail')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/17.png')} />
                <div>{t('Social Care')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/18.png')} />
                <div>{t('Sport')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/19.png')} />
                <div>{t('Tourism')}</div>
              </div>
              <div>
                <img src={require('./assets/areas/20.png')} />
                <div>{t('Oil and Gas Industry')}</div>
              </div>
            </div>
          </div>

        </div>

        <div className='service-container'>

          <div className='service-title'>
            <div>{t('Cybersecurity and More')}</div>
            <img src={require('./assets/image.png')} />
          </div>

          <div className='service-details'>
            <p dangerouslySetInnerHTML={{ __html: t('CybersecurityText') }}></p>
          </div>

        </div>
      </div>

      <Particles
        id="tsparticles1"
        init={particlesInit}
        className='particles'
        options={particlesOptions1}
      />
      <div className='about partner'>
        <div className='about-container'>
          <div>
            <div className='logoContainer'><img src={require('./assets/partner.png')} /></div>
          </div>
          <div className='about-content'>
            <div className='title'>
              <p>{t('Our Partner')}</p>
              <div></div>
            </div>
            <p>{t('PartnerText1')}</p>
            <p>{t('PartnerText2')}</p>
          </div>
        </div>

        <div className='button-container'>
          <a href='https://ukeee.co.uk/pdf/Bramfitt-Brochure.pdf' target="_blank" className='button'>{t('More Info')}</a>
        </div>
      </div>

      <div className='contact' id='contact'>
        <div className='title'>
          <p>{t('Contact Us')}</p>
          <div></div>
        </div>
        <div className='contact-container'>
          <div>
            <form>
              <div className='input'>
                <label>{t('Name')}</label>
                <input value={name} onChange={(e) => setName(e.target.value)} />
              </div>
              <div className='input'>
                <label>{t('Email')}</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className='input'>
                <label>{t('Message')}</label>
                <textarea rows={10} value={msg} onChange={(e) => setMsg(e.target.value)} />
              </div>

              <div className='button-container'>
                <a onClick={sendEmail} className='button'>{t('Send')}</a>
              </div>
            </form>
          </div>
          <div className='contact-info'>
            <div className='title'>{t('Let us help!')}</div>
            <p>{t('HelpText')}</p>

            <div className='title'>{t('Hours')}</div>
            <p>{t('Monday - Friday: 9am - 5pm')}</p>
            <p>{t('Saturday - Sunday: Closed')}</p>
          </div>
        </div>
      </div>

      <div className='team'>
        <div>
          <div className='title'>{t('Dr Esra Yahia')}</div>
          <p>{t('Director')}</p>
          <p>
            {t('London-UK')}: +447554661678<br />
            {t('Baghdad-Iraq')}: +9647844473338<br />
            {t('Email')}: esra.yahia@ukeee.co.uk<br />
          </p>
          <p>
            123 King Street<br />
            {t('London-UK')}<br />
            W6 9JG<br />
          </p>
        </div>
        <div>
          <div className='title'>{t('Mr Omar Saidi')}</div>
          <p>{t('Director and Business Development Consultant')}</p>
          <p>
            {t('Baghdad-Iraq')}: +9647813333173<br />
            {t('Email')}: omar.saidi@ukeee.co.uk<br />
          </p>
          <p>
            {t('Qadisiyah')}<br />
            {t('Baghdad-Iraq')}<br />
          </p>
        </div>
        <div>
          <div className='title'>{t('Dr Kevin McCooke')}</div>
          <p>{t('Consultant')}</p>
          <p>
            {t('London-UK')}: +447776137456<br />
            {t('Email')}: kevin.mccooke@ukeee.co.uk<br />
          </p>
          <p>
            123 King Street<br />
            {t('London-UK')}<br />
            W6 9JG<br />
          </p>
        </div>
      </div>

      <div className='footer'>
        {t('footer')}
      </div>

    </div>
  );
}

export default App;
