const en = require('../langs/en.json');
const ar = require('../langs/ar.json');

export const t = (key: string) => {
  const lang = localStorage.getItem('lang') ?? 'en';

  let findKey = key;

  if (lang === 'en') findKey = en[key];
  if (lang === 'ar') findKey = ar[key];

  return findKey ?? key;
}